import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DateTimeService } from '@examdojo/core/date-time';
import { environment } from '@examdojo/core/environment';
import { AuthService, UserQuery } from '@examdojo/user';
import { show, shutdown, update } from '@intercom/messenger-js-sdk';
import { filter, first, map, merge, switchMap, take, tap } from 'rxjs';

export const INTERCOM_APP_ID = 'k8as78sp';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(
    private readonly authService: AuthService,
    private readonly userQuery: UserQuery,
    private readonly dateTimeService: DateTimeService,
  ) {
    merge(this.initCloseOnLogoutListener(), this.setUserDataOnLogin()).pipe(takeUntilDestroyed()).subscribe();
  }

  showPanel() {
    show();
  }

  private setUserDataOnLogin() {
    return this.authService.currentUser$.pipe(
      filter(Boolean),
      take(1),
      switchMap((authUser) =>
        this.userQuery.isUserOnboarded$.pipe(
          first(Boolean),
          switchMap(() => this.userQuery.active$),
          first(Boolean),
          map((user) => ({ ...user, createdAt: this.dateTimeService.parseDate(authUser.created_at) })),
        ),
      ),
      tap((user) => {
        update({
          user_id: user.id,
          name: user.fullName,
          email: user.email,
          created_at: Math.round(user.createdAt.getTime() / 1000),
          environment: environment.name,
          release_version: environment.release_version,
        });
      }),
    );
  }
  private initCloseOnLogoutListener() {
    return this.authService.hasLoggedOut$.pipe(
      tap(() => {
        shutdown();
      }),
    );
  }
}
